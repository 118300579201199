class PathBlock {
    constructor(el) {
        if (!this.set(el)) return;
        this.init();
    }

    set(el) {
        if (!el) return false;

        this.root = el;
        this.items = this.root.querySelectorAll('.path__item:not(.-title)');

        this.timer = null;
        this.currIndex = 0;

        return true;
    }

    init() {
        this.setTimer();
    }

    setTimer() {
        if (!this.items) return;
        this.timer = setInterval(() => {
            this.currIndex += 1;
            if (this.currIndex > this.items.length - 1) this.currIndex = 0;

            const item = this.items[this.currIndex];

            this.resetItems();
            this.setActiveItem(item);
        }, 3000);
    }

    resetItems() {
        if (!this.items) return;
        this.items.forEach(item => item.classList.remove('-active'));
    }

    setActiveItem(item) {
        item.classList.add('-active');
    }
}

function initPathBlock() {
    const sections = document.querySelectorAll('[data-path-block]');
    if (!sections) return;

    sections.forEach(item => new PathBlock(item));
}

if (
    document.readyState === 'complete' ||
    (document.readyState !== 'loading' && !document.documentElement.doScroll)
) {
    initPathBlock();
} else {
    document.addEventListener('DOMContentLoaded', initPathBlock);
}
